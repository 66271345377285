
uniform float uSize;
uniform float uTime;


varying vec2 vUv;

void main()
{
    vUv = uv;

    gl_Position = projectionMatrix * modelViewMatrix * vec4(position * (0.1 + uv.x), 1.0);
}